import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s15_1_1_1 : false,
      s15_1_1_2 : false,
      s15_1_1_5 : false
    }

    this.searchInit = {
      filter1Init: 5,
      filter2Init: 0,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
        <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>次世代パワー半導体市場規模（予測①）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s15_1_1_1"}>
                <img src={'../../images/chart/s15-1-1-1.png'} alt="次世代パワー半導体市場規模（予測①）" width={559} />
              </button>
              <ImageBox
                open={this.state.s15_1_1_1}
                close={this.closeModal}
                name={"s15_1_1_1"}
                src={'/images/chart/s15-1-1-1.png'}
                alt="次世代パワー半導体市場規模（予測①）"
                width={1260}
                height={736} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="http://www.semiconductor-today.com/features/PDF/semiconductor-today-june-july-2020-GaN-and-SiC-power.pdf" target="_blank" rel="noreferrer noopener">Omdia, “SiC & GaN Power Semiconductors Report – 2020”</a>（2020年6月）</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>次世代パワー半導体市場規模（予測②）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s15_1_1_2"}>
                <img src={'../../images/chart/s15-1-1-2.png'} alt="次世代パワー半導体市場規模（予測②）" width={559} />
              </button>
              <ImageBox
                open={this.state.s15_1_1_2}
                close={this.closeModal}
                name={"s15_1_1_2"}
                src={'/images/chart/s15-1-1-2.png'}
                alt="次世代パワー半導体市場規模（予測②）"
                width={1260}
                height={1136} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://resourcecenter.ieee-pels.org/roadmap/PELSPRO0020.html" target="_blank" rel="noreferrer noopener">IEEE, “International Technology Roadmap for Wide Bandgap Power Semiconductors 2019”</a>（2019年9月）</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>世界のデータセンター市場規模の推移及び予測（カテゴリ別）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s15_1_1_5"}>
                <img src={'../../images/chart/s15-1-1-5.png'} alt="世界のデータセンター市場規模の推移及び予測（カテゴリ別）" width={559} />
              </button>
              <ImageBox
                open={this.state.s15_1_1_5}
                close={this.closeModal}
                name={"s15_1_1_5"}
                src={'/images/chart/s15-1-1-5.png'}
                alt="世界のデータセンター市場規模の推移及び予測（カテゴリ別）"
                width={415}
                height={421} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.soumu.go.jp/johotsusintokei/whitepaper/r03.html" target="_blank" rel="noreferrer noopener">総務省「令和3年版 情報通信白書」</a>（2021年7月）</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`